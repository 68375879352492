<template>
  <div class="auth">
    <div class="auth__loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
    <div class="auth__form">
      <p class="auth__title">Вход</p>
      <alert-text
          v-if="isWrongData"
          class="auth__alert"
          value="Ошибка!<br/> Неправильный логин или пароль"/>
      <alert-text
          v-if="isWrongData1"
          class="auth__alert"
          value="Вход возможен только</br>через мобильное приложение"/>
      <InputAuth
          class="auth__input"
          label="ЛОГИН"
          icon-class="user"
          type="text"
          v-model="login"
          id="login"/>
      <InputAuth
          class="auth__input"
          label="ПАРОЛЬ"
          icon-class="show"
          type="password"
          v-model="password"
          id="password"/>
      <ButtonPink
          class="auth__button"
          value="ВОЙТИ"
          size="md"
          @click.native="auth"
          :disabled="isWrongData || isWrongData1"/>
    </div>
  </div>
</template>

<script>
import AlertText from "../../components/alert/AlertText";
import InputAuth from "../../components/input/InputAuth";
import ButtonPink from "../../components/button/ButtonPink";
import {AccountGateway} from "../../gateway/AccountGateway";
import {AuthRequest} from "../../entity/requests/AuthRequest";
import {WrongLoginOrPasswordException} from "../../entity/exception/WrongLoginOrPasswordException";
import VideoLoader from "../../components/loader/VideoLoader";
export default {
  name: "AuthView",
  components: {VideoLoader, ButtonPink, InputAuth, AlertText},
  data(){
    return {
      login : "",
      password : "",
      accountGateway: new AccountGateway(),
      isWrongData: false,
      isWrongData1: false,
      isLoading: false
    }
  },
  watch:{
    login(){
      this.isWrongData = false
      this.isWrongData1 = false
    },
    password(){
      this.isWrongData = false
      this.isWrongData1 = false
    }
  },
  computed:{
    isMobile(){
      return screen.width < 992
    }
  },
  methods: {
    goToTerms(){
      window.open('https://esova.ru/terms_of_use',"_blank");
    },


    async auth(){
      try{
        this.isWrongData1 = false
        this.isLoading = true
        const auth = await this.accountGateway.auth(new AuthRequest(this.login,this.password));
        setTimeout(async () => {
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");

          localStorage.setItem("accessToken",auth.accessToken);
          localStorage.setItem("refreshToken",auth.refreshToken);
          try{
            await this.$store.dispatch("GET_PROFILE")
            this.isLoading = false
            if (this.$store.getters.PROFILE.type === 1){
              this.isWrongData1 = true
              localStorage.removeItem("accessToken")
              localStorage.removeItem("refreshToken")
            } else {
              this.$router.push({name: "ParentDashboard"})
            }
          } catch (e) {
            this.isLoading = false
            this.$router.push({name: "InterviewView"})
          }
        }, 1000)
        this.isWrongData = false;

      } catch (e) {
        this.isLoading = false
        if (e instanceof WrongLoginOrPasswordException){
          this.isWrongData = true;
        } else {
          console.log(e)
          alert(e.stack)
        }
      }
    },
  },
  mounted(){
  },

  created(){
    window.addEventListener('keyup', (event) => {
      if (event.keyCode == 13) {
        this.auth()
      }
    });
  }
}
</script>

<style scoped lang="scss">
  .auth{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__form{
      background: #FFFFFF;
      border: 1px solid rgba(198,206,212,0.50);
      border-radius: 4px;
      width: 350px;
      height: auto;
      min-height: 350px;
      padding-left: 30px;
    }

    &__title{
      margin-top: 28px;
      font-family: CodeNext;
      font-weight: 800;
      letter-spacing: 0;
      font-size: 24px;
      color: #222222;
    }

    &__alert{
      margin-top: 10px;
      margin-bottom: -10px;

    }

    &__input{
      margin-top: 30px;
    }

    &__button{
      margin-top: 47px;
      margin-bottom: 45px;
    }

    &__loader{
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.16);
      position: absolute;
      z-index: 1;

      div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
      }
    }


  }
</style>
